/* Icon Fade */
.icon-fade() {
	.hacks();

	.hvr-icon {
		.prefixed(transform, translateZ(0));
		.prefixed(transition-duration, .5s);
		.prefixed(transition-property, color);
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			color: #0F9E5E;
		}
	}
}
