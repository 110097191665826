/* Shadow */
.shadow() {
	.hacks();
	.prefixed(transition-duration, @mediumDuration);
	.prefixed(transition-property, box-shadow);

	&:hover,
	&:focus,
	&:active {
		box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
	}
}
