/* Hollow */
.hollow() {
	@borderWidth: 4px;

	.hacks();
	.prefixed(transition-duration, @mediumDuration);
	.prefixed(transition-property, background);
	box-shadow:
		inset 0 0 0 @borderWidth @primaryColor,
		0 0 1px rgba(0, 0, 0, 0); /* Hack to improve aliasing on mobile/tablet devices */

	&:hover,
	&:focus,
	&:active {
		background: none;
	}
}
