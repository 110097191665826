/* Icon Back */
.icon-back() {
	.hacks();
	.prefixed(transition-duration, @fastDuration);

	.hvr-icon {
		.prefixed(transform, translateZ(0));
		.prefixed(transition-duration, @fastDuration);
		.prefixed(transition-property, transform);
		.prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			.prefixed(transform, translateX(-4px));
		}
	}
}
