/* Skew Forward */
.skew-forward() {
	.hacks();
	.prefixed(transition-duration, @mediumDuration);
	.prefixed(transition-property, transform);
	.prefixed(transform-origin, 0 100%);

	&:hover,
	&:focus,
	&:active {
		.prefixed(transform, skew(-10deg));
	}
}
