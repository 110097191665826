/* Buzz */
.keyframes(~"@{nameSpace}-buzz", {
	50% {
		.prefixed(transform, translateX(3px) rotate(2deg));
	}

	100% {
		.prefixed(transform, translateX(-3px) rotate(-2deg));
	}
});

.buzz() {
	.hacks();

	&:hover,
	&:focus,
	&:active {
		.prefixed(animation-name, ~"@{nameSpace}-buzz");
		.prefixed(animation-duration, .15s);
		.prefixed(animation-timing-function, linear);
		.prefixed(animation-iteration-count, infinite);
	}
}
