/* Curl Bottom Left */
.curl-bottom-left() {
	.hacks();
	position: relative;

	&:before {
		pointer-events: none;
		position: absolute;
		content: '';
		height: 0;
		width: 0;
		bottom: 0;
		left: 0;
		background: @revealAreaColor; /* IE9 */
		background: linear-gradient(
			45deg,
			@revealAreaColor 45%,
			@curlLineColor 50%,
			@curlTransitionColor 56%,
			@curlLastColor 80%
		);
		box-shadow: 1px -1px 1px rgba(0, 0, 0, .4);
		.prefixed(transition-duration, @mediumDuration);
		.prefixed(transition-property, ~"width, height");
	}

	&:hover:before,
	&:focus:before,
	&:active:before {
		width: @curlWidth;
		height: @curlHeight;
	}
}
