/* Icon Drop */
.keyframes(~"@{nameSpace}-icon-drop", {
	0% {
		opacity: 0;

	}

	50% {
		opacity: 0;
		.prefixed(transform, translateY(-100%));
	}

	51%,
	100% {
		opacity: 1;
	}
});

/* Icon Drop */
.icon-drop() {
	.hacks();

	.hvr-icon {
		.prefixed(transform, translateZ(0));
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			opacity: 0;
			.prefixed(transition-duration, @mediumDuration);
			.prefixed(animation-name, ~"@{nameSpace}-icon-drop");
			.prefixed(animation-duration, @slowDuration);
			.prefixed(animation-delay, @mediumDuration);
			.prefixed(animation-fill-mode, forwards);
			.prefixed(animation-timing-function, ease-in-out);
			.prefixed(animation-timing-function, cubic-bezier(.52,1.64,.37,.66));
		}
	}
}
