/* Glow */
.glow() {
	.hacks();
	.prefixed(transition-duration, @mediumDuration);
	.prefixed(transition-property, box-shadow);

	&:hover,
	&:focus,
	&:active {
		box-shadow: 0 0 8px @shadowColor;
	}
}
