/* Icon Buzz */
.keyframes(~"@{nameSpace}-icon-buzz", {
	50% {
		.prefixed(transform, translateX(3px) rotate(2deg));
	}

	100% {
		.prefixed(transform, translateX(-3px) rotate(-2deg));
	}
});

.icon-buzz() {
	.hacks();
	.prefixed(transition-duration, @mediumDuration);

	.hvr-icon {
		.prefixed(transform, translateZ(0));
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			.prefixed(animation-name, ~"@{nameSpace}-icon-buzz");
			.prefixed(animation-duration, .15s);
			.prefixed(animation-timing-function, linear);
			.prefixed(animation-iteration-count, infinite);
		}
	}
}
