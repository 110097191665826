/* Icon Pop */
.keyframes(~"@{nameSpace}-icon-pop", {
	50% {
		.prefixed(transform, scale(1.5));
	}
});

.icon-pop() {
	.hacks();
	.prefixed(transition-duration, @mediumDuration);

	.hvr-icon {
		.prefixed(transform, translateZ(0));
		.prefixed(transition-duration, @mediumDuration);
		.prefixed(transition-property, transform);
		.prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			.prefixed(animation-name, ~"@{nameSpace}-icon-pop");
			.prefixed(animation-duration, @mediumDuration);
			.prefixed(animation-timing-function, linear);
			.prefixed(animation-iteration-count, 1);
		}
	}
}
