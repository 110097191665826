/* Icon Pulse Shrink */
.keyframes(~"@{nameSpace}-icon-pulse-shrink", {
	to {
		.prefixed(transform, scale(.8));
	}
});

.icon-pulse-shrink() {
	.hacks();

	.hvr-icon {
		.prefixed(transform, translateZ(0));
		.prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			.prefixed(animation-name, ~"@{nameSpace}-icon-pulse-shrink");
			.prefixed(animation-duration, @mediumDuration);
			.prefixed(animation-timing-function, linear);
			.prefixed(animation-iteration-count, infinite);
			.prefixed(animation-direction, alternate);
		}
	}
}
