/* Bubble Bottom */
.bubble-bottom() {
	.hacks();
	position: relative;

	&:before {
		pointer-events: none;
		position: absolute;
		z-index: -1;
		content: '';
		border-style: solid;
		.prefixed(transition-duration, @mediumDuration);
		.prefixed(transition-property, transform);
		left: calc(~"50%" - @tipWidth);
		bottom: 0;
		border-width: @tipHeight @tipWidth 0 @tipWidth;
		border-color: @tipColor transparent transparent transparent;
	}

	&:hover:before,
	&:focus:before,
	&:active:before {
		.prefixed(transform, translateY(@tipHeight));
	}
}
