/* Bounce In */
.bounce-in() {
	.hacks();
	.prefixed(transition-duration, @slowDuration);

	&:hover,
	&:focus,
	&:active {
		.prefixed(transform, scale(1.2));
		.prefixed(transition-timing-function, cubic-bezier(.47,2.02,.31,-0.36));
	}
}
