/* Icon Wobble Horizontal */
.keyframes(~"@{nameSpace}-icon-wobble-horizontal", {
	16.65% {
		.prefixed(transform, translateX(6px));
	}

	33.3% {
		.prefixed(transform, translateX(-5px));
	}

	49.95% {
		.prefixed(transform, translateX(4px));
	}

	66.6% {
		.prefixed(transform, translateX(-2px));
	}

	83.25% {
		.prefixed(transform, translateX(1px));
	}

	100% {
		.prefixed(transform, translateX(0));
	}
});

.icon-wobble-horizontal() {
	.hacks();
	.prefixed(transition-duration, @mediumDuration);

	.hvr-icon {
		.prefixed(transform, translateZ(0));
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			.prefixed(animation-name, ~"@{nameSpace}-icon-wobble-horizontal");
			.prefixed(animation-duration, 1s);
			.prefixed(animation-timing-function, ease-in-out);
			.prefixed(animation-iteration-count, 1);
		}
	}
}
