/* Icon Bounce */

.icon-bounce() {
	.hacks();
	.prefixed(transition-duration, @mediumDuration);

	.hvr-icon {
		.prefixed(transform, translateZ(0));
		.prefixed(transition-duration, @mediumDuration);
		.prefixed(transition-property, transform);
		.prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			.prefixed(transform, scale(1.5));
			.prefixed(transition-timing-function, cubic-bezier(.47,2.02,.31,-0.36));
		}
	}
}
