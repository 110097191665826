/* Overline From Left */
.overline-from-left() {
	@duration: @mediumDuration;

	.hacks();
	position: relative;
	overflow: hidden;

	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		left: 0;
		right: 100%;
		top: 0;
		background: @activeColor;
		height: 4px;
		.prefixed(transition-property, "right");
		.prefixed(transition-duration, @duration);
		.prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {

		&:before {
			right: 0;
		}
	}
}
