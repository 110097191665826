/* Wobble Vertical */
.keyframes(~"@{nameSpace}-wobble-vertical", {
	16.65% {
		.prefixed(transform, translateY(8px));
	}

	33.3% {
		.prefixed(transform, translateY(-6px));
	}

	49.95% {
		.prefixed(transform, translateY(4px));
	}

	66.6% {
		.prefixed(transform, translateY(-2px));
	}

	83.25% {
		.prefixed(transform, translateY(1px));
	}

	100% {
		.prefixed(transform, translateY(0));
	}
});

.wobble-vertical() {
	.hacks();

	&:hover,
	&:focus,
	&:active {
		.prefixed(animation-name, ~"@{nameSpace}-wobble-vertical");
		.prefixed(animation-duration, 1s);
		.prefixed(animation-timing-function, ease-in-out);
		.prefixed(animation-iteration-count, 1);
	}
}
