/* Icon Sink Away */
.keyframes(~"@{nameSpace}-icon-sink-away", {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		.prefixed(transform, translateY(1em));
	}
});

/* Icon Sink Away */
.icon-sink-away() {
	.hacks();

	.hvr-icon {
		.prefixed(transform, translateZ(0));
		.prefixed(animation-duration, @slowDuration);
		.prefixed(animation-fill-mode, forwards);
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			.prefixed(animation-name, ~"@{nameSpace}-icon-sink-away");
			.prefixed(animation-timing-function, ease-out);
		}
	}
}
