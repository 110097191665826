/* Wobble To Top Right */
.keyframes(~"@{nameSpace}-wobble-to-top-right", {
  16.65% {
    .prefixed(transform, translate(8px, -8px));
  }

  33.3% {
    .prefixed(transform, translate(-6px, 6px));
  }

  49.95% {
    .prefixed(transform, translate(4px, -4px));
  }

  66.6% {
    .prefixed(transform, translate(-2px, 2px));
  }

  83.25% {
    .prefixed(transform, translate(1px, -1px));
  }

  100% {
    .prefixed(transform, translate(0, 0));
  }
});

.wobble-to-top-right() {
	.hacks();

	&:hover,
    &:focus,
    &:active {
        .prefixed(animation-name, ~"@{nameSpace}-wobble-to-top-right");
        .prefixed(animation-duration, 1s);
        .prefixed(animation-timing-function, ease-in-out);
        .prefixed(animation-iteration-count, 1);
	}
}
