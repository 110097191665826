/* Bounce To Right */
.bounce-to-right() {
	.hacks();
	position: relative;
	.prefixed(transition-property, color);
	.prefixed(transition-duration, @slowDuration);

	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: @activeColor;
		.prefixed(transform, scaleX(0));
		.prefixed(transform-origin, 0 50%);
		.prefixed(transition-property, transform);
		.prefixed(transition-duration, @slowDuration);
		.prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {
		color: white;

		&:before {
			.prefixed(transform, scaleX(1));
			.prefixed(transition-timing-function, cubic-bezier(.52,1.64,.37,.66));
		}
	}
}
