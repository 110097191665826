/* Underline Reveal */
.underline-reveal() {
	@duration: @mediumDuration;

	.hacks();
	position: relative;
	overflow: hidden;

	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		left: 0;
		right: 0;
		bottom: 0;
		background: @activeColor;
		height: 4px;
		.prefixed(transform, translateY(4px));
		.prefixed(transition-property, transform);
		.prefixed(transition-duration, @duration);
		.prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {

		&:before {
			.prefixed(transform, translateY(0));
		}
	}
}
