/* Pulse */
.keyframes(~"@{nameSpace}-pulse", {
	25% {
		.prefixed(transform, scale(1.1));
	}

	75% {
		.prefixed(transform, scale(.9));
	}
});

.pulse() {
	.hacks();

	&:hover,
	&:focus,
	&:active {
		.prefixed(animation-name, ~"@{nameSpace}-pulse");
		.prefixed(animation-duration, 1s);
		.prefixed(animation-timing-function, linear);
		.prefixed(animation-iteration-count, infinite);
	}
}
