/* Outline In */
.outline-in() {
	@outerBorderWidth: 4px;
	@innerBorderWidth: 4px;

	.hacks();
	position: relative;

	&:before {
		pointer-events: none;
		content: '';
		position: absolute;
		border: @primaryColor solid @outerBorderWidth;
		top: -(@outerBorderWidth + @innerBorderWidth) * 2;
		right: -(@outerBorderWidth + @innerBorderWidth) * 2;
		bottom: -(@outerBorderWidth + @innerBorderWidth) * 2;
		left: -(@outerBorderWidth + @innerBorderWidth) * 2;
		opacity: 0;
		.prefixed(transition-duration, .3s);
		.prefixed(transition-property, "top, right, bottom, left");
	}

	&:hover:before,
	&:focus:before,
	&:active:before {
		top: -(@outerBorderWidth + @innerBorderWidth);
		right: -(@outerBorderWidth + @innerBorderWidth);
		bottom: -(@outerBorderWidth + @innerBorderWidth);
		left: -(@outerBorderWidth + @innerBorderWidth);
		opacity: 1;
	}
}
