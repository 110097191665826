/* Wobble Skew */
.keyframes(~"@{nameSpace}-wobble-skew", {
	16.65% {
		.prefixed(transform, skew(-12deg));
	}

	33.3% {
		.prefixed(transform, skew(10deg));
	}

	49.95% {
		.prefixed(transform, skew(-6deg));
	}

	66.6% {
		.prefixed(transform, skew(4deg));
	}

	83.25% {
		.prefixed(transform, skew(-2deg));
	}

	100% {
		.prefixed(transform, skew(0));
	}
});

.wobble-skew() {
	.hacks();

	&:hover,
	&:focus,
	&:active {
		.prefixed(animation-name, ~"@{nameSpace}-wobble-skew");
		.prefixed(animation-duration, 1s);
		.prefixed(animation-timing-function, ease-in-out);
		.prefixed(animation-iteration-count, 1);
	}
}
