/* Icon Pulse */
.keyframes(~"@{nameSpace}-icon-pulse", {
	25% {
		.prefixed(transform, scale(1.3));
	}

	75% {
		.prefixed(transform, scale(.8));
	}
});

.icon-pulse() {
	.hacks();

	.hvr-icon {
		.prefixed(transform, translateZ(0));
		.prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			.prefixed(animation-name, ~"@{nameSpace}-icon-pulse");
			.prefixed(animation-duration, 1s);
			.prefixed(animation-timing-function, linear);
			.prefixed(animation-iteration-count, infinite);
		}
	}
}
