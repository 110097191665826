/* Icon Up */
.keyframes(~"@{nameSpace}-icon-up", {
	0%,
	50%,
	100% {
		.prefixed(transform, translateY(0));
	}
	25%,
	75% {
		.prefixed(transform, translateY(-6px));
	}
});

/* Icon Up */
.icon-up() {
	.hacks();

	.hvr-icon {
		.prefixed(transform, translateZ(0));
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			.prefixed(animation-name, ~"@{nameSpace}-icon-up");
			.prefixed(animation-duration, .75s);
			.prefixed(animation-timing-function, ease-out);
		}
	}
}
