html,
body,
#root {
  height: 100%;
}
.basicLayoutCenter {
  display: flex;
  flex-direction: column;
  flex: 1;
  & > div {
    flex: 1;
  }
}
// @font-face {
//   font-family: 'PingFangSC-Regular';
//   src: url('../src/assets/fonts/PingFangSC-Regular.ttf');
// }

// @font-face {
//   font-family: 'PingFangSC-Semibold';
//   src: url('../src/assets/fonts/PingFangSC-Semibold.ttf');
// }

// @font-face {
//   font-family: 'PingFangSC-Blod';
//   src: url('../src/assets/fonts/PingFangSC-Blod.ttf');
// }

// @font-face {
//   font-family: 'PingFangSC-Regular';
//   src: url('../src/assets/fonts/PingFangSC-Regular.ttf');
// }

@font-face {
  font-family: 'Akrobat-ExtraBold';
  src: url('../src/assets/fonts/Akrobat-ExtraBold.otf');
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f5f5f5;
}

.AppContent {
  flex: 1;
}

input {
  flex: 1;
  border: none;
  outline: 0;
}

button {
  border: 0;
  outline: none;
}

.iconfont {
  font-size: 12px;
  line-height: 8px;
  color: #ccc;

  &.blue {
    color: #007bdd;
  }
}

/* 1920 */
@media (min-width: 1200px) {
  .Container {
    width: 1200px;
    margin: 0 auto;
  }
}

/* 1280*/
@media (max-width: 1200px) {
  .Container {
    width: 100%;
    padding: 0 15px;
  }
}

* {
  font-family: PingFangSC-Regular, PingFang SC;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
ul,
ol,
li,
p,
blockquote,
pre,
hr,
figure,
table,
caption,
th,
td,
form,
fieldset,
legend,
input,
button,
textarea,
menu {
  margin: 0;
  padding: 0;
  word-break: break-all;
  overflow-wrap: break-word;
}

header,
footer,
section,
article,
aside,
nav,
hgroup,
address,
figure,
figcaption,
menu,
details {
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
  font-weight: normal;
}

html,
body,
fieldset,
img,
iframe,
abbr {
  border: 0;
}

i,
cite,
em,
var,
address,
dfn {
  font-style: normal;
}

[hidefocus],
summary {
  outline: 0;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
small {
  font-size: 100%;
}

sup,
sub {
  font-size: 83%;
}

pre,
code,
kbd,
samp {
  font-family: inherit;
}

q:before,
q:after {
  content: none;
}

textarea {
  overflow: auto;
  resize: none;
}

label,
summary {
  cursor: default;
}

a,
button {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
em,
strong,
b {
  font-weight: bold;
}

del,
ins,
u,
s,
a,
a:hover {
  text-decoration: none;
}

body,
textarea,
input,
button,
select,
keygen,
legend {
  font: 12px/1.14 arial, \5b8b\4f53;
  color: #333;
  outline: 0;
}

body {
  background: #fff;
}

a,
a:hover {
  color: #333;
}

input {
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*解决ios上按钮的圆角问题*/
  border-radius: 0;
  /*解决ios上输入框圆角问题*/
  outline: medium;
  /*去掉鼠标点击的默认黄色边框*/
  background-color: transparent;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

input,
textarea {
  &::-webkit-input-placeholder {
    /* Edge */
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #bfc4cd;
    line-height: 20px;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #bfc4cd;
    line-height: 20px;
  }

  &::placeholder {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #bfc4cd;
    line-height: 20px;
  }
}

button {
  // background: transparent;
  // border: none;

  // &::before,
  // &::after {
  //   border: none;
  //   display: none;
  // }
}

.backBreadcrumb {
  margin-left: 10px;
  height: 34px;
  background: rgba(5, 46, 133, 0.15);
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #052e85;
  line-height: 34px;
  padding: 0 23px;
}
.adm-text-area-element {
  padding: 4px 0;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #202020;
  line-height: 20px;
}
.placeholder {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(51, 51, 51, 0.4);
  line-height: 16px;
}

.adm-modal-body,
.adm-modal-content {
  max-height: 90vh !important;
  width: 100%;
}
.adm-modal-body > .adm-modal-content {
  padding: 0 !important;
}

.adm-center-popup-body {
  border-radius: 0 !important;
}
.adm-center-popup-wrap {
  max-width: 100% !important;
}
.adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button {
  color: #cc1e0f;
  &:last-child {
    color: #052e85;
  }
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.justify-start {
  display: flex;
  justify-content: flex-start;
}
.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}
.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}
.justify-around {
  display: flex;
  justify-content: space-around;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.align-start {
  display: flex;
  align-items: flex-start;
}
.align-center {
  display: flex;
  align-items: center;
}
.align-end {
  display: flex;
  align-items: flex-end;
}

.video-react .video-react-big-play-button {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}
