/* Shrink */
.shrink() {
	.hacks();
	.prefixed(transition-duration, @mediumDuration);
	.prefixed(transition-property, transform);

	&:hover,
	&:focus,
	&:active {
		.prefixed(transform, scale(.9));
	}
}
