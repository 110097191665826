/* Radial In */
.radial-in() {
	.hacks();
	position: relative;
	overflow: hidden;
	background: @activeColor;
	.prefixed(transition-property, color);
	.prefixed(transition-duration, @mediumDuration);

	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: @primaryColor;
		border-radius: 100%;
		.prefixed(transform, scale(2));
		.prefixed(transition-property, transform);
		.prefixed(transition-duration, @mediumDuration);
		.prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {
		color: white;

		&:before {
			.prefixed(transform, scale(0));
		}
	}
}
