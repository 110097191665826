/* Pop */
.keyframes(~"@{nameSpace}-pop", {
	50% {
		.prefixed(transform, scale(1.2));
	}
});

.pop() {
	.hacks();

	&:hover,
	&:focus,
	&:active {
		.prefixed(animation-name, ~"@{nameSpace}-pop");
		.prefixed(animation-duration, @mediumDuration);
		.prefixed(animation-timing-function, linear);
		.prefixed(animation-iteration-count, 1);
	}
}
