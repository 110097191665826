/* Fade */
.fade() {
	.hacks();
	overflow: hidden;
	.prefixed(transition-duration, @mediumDuration);
	.prefixed(transition-property, ~"color, background-color");

	&:hover,
	&:focus,
	&:active {
		background-color: @activeColor;
		color: white;
	}
}
